import { NavLink } from "react-router-dom";
import { loadScript, route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { useEffect, useState } from "react";

function SectionHeader1() {

    const [isActive, setIsActive] = useState(false);

    useEffect(()=>{
        loadScript("js/sticky-header.js");
    })

    const handleToggleMenu = () => {
        setIsActive(!isActive);
    }

    return (
        <header className={"site-header header-style-2 topbar-transparent mobile-sider-drawer-menu "+(isActive? 'active' : '')}>
            <div className="sticky-header main-bar-wraper">
                <div className="main-bar">
                    <div className="container">
                        <div className="logo-header">
                            <NavLink to={route.home.HOME1}>
                                <FastImage src="images/logo-1.png" width={216} height={37} alt="" />
                            </NavLink>
                        </div>
                        {/* NAV Toggle Button */}
                        <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button" className="navbar-toggler collapsed" onClick={handleToggleMenu}>
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar icon-bar-first" />
                            <span className="icon-bar icon-bar-two" />
                            <span className="icon-bar icon-bar-three" />
                        </button>
                        {/* ETRA Nav */}
                        <div className="extra-nav">
                            {/* <div className="extra-cell">
                                <a href="#" className="mt-cart cart-btn dropdown-toggle" title="Your Cart" id="ID-MSG_dropdown" data-bs-toggle="dropdown">
                                    <span className="link-inner">
                                        <span className="woo-cart-total"> </span>
                                        <span className="woo-cart-count">
                                            <i className="fa fa-shopping-cart" />
                                            <span className="shopping-bag wcmenucart-count ">2</span>
                                        </span>
                                    </span>
                                </a>
                                <div className="dropdown-menu cart-dropdown-item-wraper " aria-labelledby="ID-MSG_dropdown">
                                    <div className="clearfix">
                                        <div className="nav-cart-content">
                                            <div className="nav-cart-items p-a10">
                                                <div className="nav-cart-item clearfix">
                                                    <div className="nav-cart-item-image">
                                                        <NavLink to={route.shop.DETAIL}><FastImage src="images/cart/pic-1.jpg" alt="p-1" /></NavLink>
                                                    </div>
                                                    <div className="nav-cart-item-desc">
                                                        <NavLink to={route.shop.DETAIL}>Air Fright</NavLink>
                                                        <span className="nav-cart-item-price"><strong>2</strong> x $9.50</span>
                                                        <a href="#" className="nav-cart-item-quantity">x</a>
                                                    </div>
                                                </div>
                                                <div className="nav-cart-item clearfix">
                                                    <div className="nav-cart-item-image">
                                                        <NavLink to={route.shop.DETAIL}><FastImage src="images/cart/pic-2.jpg" alt="p-2" /></NavLink>
                                                    </div>
                                                    <div className="nav-cart-item-desc">
                                                        <NavLink to={route.shop.DETAIL}>Transport Item</NavLink>
                                                        <span className="nav-cart-item-price"><strong>1</strong> x $24.50</span>
                                                        <a href="#" className="nav-cart-item-quantity">x</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="nav-cart-title p-a15 clearfix">
                                                <h4 className="pull-left m-a0">Subtotal:</h4>
                                                <h5 className="pull-right m-a0">$43.50</h5>
                                            </div>
                                            <div className="nav-cart-action">
                                                <button className="site-button" type="button">View Cart</button>
                                                <button className="site-button-secondry center-block" type="button">Checkout </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                             <div className="header-nav navbar-collapse ">
                            <ul className=" nav navbar-nav">
                            <li><NavLink to={route.pages.gallery.GALLERY2}>Gallery</NavLink></li>
                            </ul>
                        </div>
                        </div>

                        {/* MAIN Vav */}
                        <div className="header-nav navbar-collapse ">
                            <ul className=" nav navbar-nav">
                                <li className="active">
                                    <a href="/">Home</a>

                                </li>

                                <li>
                                    <a href="#">Services<i className="fa fa-chevron-down" /></a>
                                    <ul className="sub-menu">
                                        {/* <li><NavLink to={route.services.SERVICES}>Services</NavLink></li> */}
                                        <li><NavLink to={route.services.DETAIL}>Road Freight</NavLink></li>
                                        <li><NavLink to={route.services.DETAIL}>Sea Freight</NavLink></li>
                                        <li><NavLink to={route.services.DETAIL}>Air Freight</NavLink></li>
                                        <li><NavLink to={route.services.DETAIL}>Warehouse</NavLink></li>
                                        <li><NavLink to={route.services.DETAIL}>Door to Door Services</NavLink></li>
                                        <li><NavLink to={route.services.DETAIL}>Packing & Moving</NavLink></li>
                                    </ul>
                                </li>

                                 <li><NavLink to={route.pages.ABOUT}>About us</NavLink></li>
                                 <li><NavLink to={route.pages.team.TEAM}>Our Team</NavLink></li>
                                 <li><NavLink to={route.pages.contact.CONTACT1}>Contact us </NavLink></li>
                                 <li><NavLink to={route.pages.FAQ}>FAQ</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    )
}
export default SectionHeader1;